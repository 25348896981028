module.exports = [{
      plugin: require('../node_modules/gatsby-theme-fela/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ReactBoys.com","short_name":"ReactBoys","start_url":"/","background_color":"#f7f0eb","theme_color":"#0000FF","display":"standalone","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"271f5e94df3ecad78027701b18b3d089"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NMCGH86","includeInDevelopment":false,"enableWebVitalsTracking":true,"routeChangeEventName":"route_changed","defaultDataLayer":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
