import IBMPlexMonoBold from './IBMPlexMono-Bold.ttf';
import IBMPlexMonoMedium from './IBMPlexMono-Medium.ttf';
import IBMPlexMonoLight from './IBMPlexMono-Light.ttf';

const fonts = [
  {
    name: 'IBMPlexMono',
    files: [IBMPlexMonoBold],
    options: {
      fontStyle: 'normal',
      fontWeight: '800',
      fontDisplay: 'swap',
    },
  },
  {
    name: 'IBMPlexMono',
    files: [IBMPlexMonoMedium],
    options: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontDisplay: 'swap',
    },
  },
  {
    name: 'IBMPlexMono',
    files: [IBMPlexMonoLight],
    options: {
      fontStyle: 'normal',
      fontWeight: '300',
      fontDisplay: 'swap',
    },
  },
  {
    name: 'IBMPlexMono',
    files: [IBMPlexMonoLight],
    options: {
      fontStyle: 'normal',
      fontWeight: '100',
      fontDisplay: 'swap',
    },
  },
];

export default fonts;
