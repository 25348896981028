// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import layout from '!css-loader!./layout.css';

// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import reset from '!css-loader!./reset.css';

export { default as theme } from './theme';

const styles = [reset, layout];

export default styles;
