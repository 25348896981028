const namedKeys = {
  desktopFullHD: '@media (min-width: 1921px)',
  desktopLarge: '@media (min-width: 1440px)',
  desktop: '@media (min-width: 1025px)',
  phoneLarge: '@media (min-width: 425px)',
  tablet: '@media (min-width: 768px)',
  tabletLarge: '@media (min-width: 1024px)',
};

export default namedKeys;
