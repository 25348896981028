import type { TRule } from 'fela';

const theme = {
  colors: {
    black: `#000`,
    white: `#FFF`,
    blue: `#0000FF`,
    cyan: `#00FFFF`,
    fuchsia: `#FF00FF`,
  },
  sizing: {
    maxSectionWidth: `90rem`,
    sectionPadding: `7.5rem 2rem`,
    sectionPaddingSmall: `3rem 1rem`,
  },
  transition: `cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s`,
  transitionCard: `transform cubic-bezier(0.5,0.25,0,1) 0.6s`,
};

export type Theme = typeof theme;

export type ThemeProps = { theme: Theme };

export type TRuleWithTheme<Props = {}> = TRule<ThemeProps & Props>;

export type RulesExtend<TExtendableRules, TProps = {}> = Partial<
  Record<keyof TExtendableRules, TRuleWithTheme<TProps>>
>;

export default theme;
